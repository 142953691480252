import React, { useState, useEffect } from 'react';
import './Offer.css';
import API_URL from '../../../apiconfig';

const Offer = () => {
    const [time, setTime] = useState(4500); // 1 hour 15 minutes = 4500 seconds
    const [offerBanner, setOfferBanner] = useState('Buy 2 Get 1 Free (Add 3 item to cart)'); // Default text
    const [bannerImage, setBannerImage] = useState('https://images.meesho.com/images/widgets/OY6J5/xwgyl_800.webp'); // Default image

    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchOfferBanner = async () => {
            try {
                const response = await fetch(`${API_URL}/homebanner/offer_banner`);
                const data = await response.json();
                if (data.length > 0) {
                    setOfferBanner(data[0]); // Assuming the response is in the format ["Free COD"]
                }
            } catch (error) {
                console.error('Error fetching offer banner:', error);
            }
        };

        const fetchLastBanner = async () => {
            try {
                const response = await fetch(`${API_URL}/homebanner/lastBanner`);
                const data = await response.json();
                if (data.length > 0) {
                    setBannerImage(data[0]); // Assuming the response is in the format ["https://..."]
                }
            } catch (error) {
                console.error('Error fetching last banner:', error);
            }
        };

        const fetchData = async () => {
            await Promise.all([fetchOfferBanner(), fetchLastBanner()]);
            setLoading(false); // Set loading to false after both fetches
        };

        fetchData();

        const timerId = setInterval(() => {
            setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timerId);
    }, []);

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;
        return `${h.toString().padStart(2, '0')}h : ${m.toString().padStart(2, '0')}m : ${s.toString().padStart(2, '0')}s`;
    };

    // Return nothing until data is loaded
    if (loading) {
        return null; // Do not render anything while loading
    }

    return (
        <>
            <div className="menu">
                <div className="menu__item">
                    <div className="marquee">
                        <div className="marquee__inner">
                            <span>{offerBanner}</span>
                            <span>&nbsp;</span>
                            <span>{offerBanner}</span>
                            <span>&nbsp;</span>
                            <span>{offerBanner}</span>
                            <span>&nbsp;</span>
                            <span>{offerBanner}</span>
                            <span>&nbsp;</span>
                            <span>{offerBanner}</span>
                        </div>
                    </div>
                </div>
            </div>
            <img 
                src={bannerImage} 
                alt="Offer banner" 
                className="w-100 mb-2" 
                style={{ width: '375px', height: '58px', objectFit: 'cover' }} 
            />
            <div color="white" className="sc-bcXHqe eCQLMa">
                <div className="sc-bcXHqe fctQgp OfferTimerSubtitle__StyledSubtitleContainer-sc-9svgo6-0 JgdIi OfferTimerSubtitle__StyledSubtitleContainer-sc-9svgo6-0 JgdIi" color="white">
                    <p style={{ fontWeight: 'book', color: 'greyT1', fontFamily: 'Mier Book' }} className="sc-dkrFOg kZrHuA">
                        Meesho Daily Deals
                        <span>
                            <svg width="19" height="22" fill="none" xmlns="http://www.w3.org/2000/svg" iconsize="20" className="sc-bcXHqe bMWbfM">
                                <mask id="deal_svg__a" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="17" height="22">
                                    <path d="M5.198.795a.73.73 0 0 0-.699.522l-2.621 8.858a1.458 1.458 0 0 0 1.568 1.862l6.018-.708L6.835 19.8a1.089 1.089 0 0 0 1.944.929l8.174-12.206c.698-1.043-.16-2.423-1.405-2.257l-5.033.671 1.724-5.183a.73.73 0 0 0-.692-.96H5.198Z" fill="#fff"></path>
                                </mask>
                                <g mask="url(#deal_svg__a)">
                                    <path fill="#E11900" d="M-.208.947H18.75v21.875H-.208z"></path>
                                </g>
                            </svg>
                        </span>
                    </p>
                    <div data-testid="offer-wrapper" className="OfferTimerstyled__StyledMediumOfferWrapper-sc-1otdyo0-0 eVLdcs">
                        <img 
                            alt="Offer clock icon" 
                            loading="lazy" 
                            width="16" 
                            height="16" 
                            decoding="async" 
                            data-nimg="1" 
                            style={{ color: 'transparent', marginRight: '4px' }} 
                            src="https://images.meesho.com/images/offer-widget-group-icon/T1W36/7tepb.png" 
                        />
                        <span 
                            style={{ fontSize: '13px', fontWeight: 'demi' }} 
                            data-testid="offer-timer-text" 
                            className="sc-dkrFOg bZxmrh OfferTimer__Subtitle2Styled-sc-dfvdiq-1 jFYRpH" 
                            color="pinkBase"
                        >
                            {formatTime(time)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Offer;
