import React, { useState } from 'react';
import './Search.css';

const Search = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    onSearch(newQuery); // Send query back to Home component
  };

  return (
    <div className="search-bar">
      <div className="search-wrapper" id="guidSearch">
        <div className="search-container">
          <div className="search-inner">
            <div className="search-placeholder">
              <div className="search-icon">
                <img src='assets/search.svg' alt='search' />
              </div>
              <input
                type="text"
                placeholder="Search for Sarees, Kurtis, Cosmetics, etc."
                className="search-input"
                value={query}
                onChange={handleChange} // Update on input change
                style={{ fontSize: "14px", fontWeight: "400" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
