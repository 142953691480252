import React, { useEffect, useState } from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import API_URL from '../../../../apiconfig';

const defaultImages = [
  "https://images.meesho.com/images/marketing/1706108173253_600.webp",
  "https://images.meesho.com/images/marketing/1705665922742_600.webp",
  "https://images.meesho.com/images/marketing/1705664942315_600.webp"
];

const Carousel = () => {
  const [sliderImages, setSliderImages] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchSliderImages = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/sliderbanner`);
        const data = await response.json();

        // Check if data is an array and has images; if not, use default images
        if (Array.isArray(data) && data.length > 0) {
          const images = data.flat(); 
          setSliderImages(images.length > 0 ? images : defaultImages);
        } else {
          setSliderImages(defaultImages);
        }
      } catch (error) {
        console.error('Error fetching slider images:', error);
        setSliderImages(defaultImages); // Use default images on error
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchSliderImages();
  }, []); // Run only once

  // Return nothing until sliderImages is populated
  if (loading) {
    return null; // Do not render anything while loading
  }

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div className="carousel-content">
          <div className="carousel-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 p-0">
                  <BootstrapCarousel interval={5000} indicators={true} controls={false}>
                    {sliderImages.map((image, index) => (
                      <BootstrapCarousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image}
                          alt={`Slide ${index + 1}`}
                          style={{
                            width: '375px',
                            height: '156px',
                            objectFit: 'cover'
                          }}
                        />
                      </BootstrapCarousel.Item>
                    ))}
                  </BootstrapCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
