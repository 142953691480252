import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Products.css';
import API_URL from '../../../apiconfig';

const Products = ({ categoryToShow = [] }) => {
  const [products, setProducts] = useState([]);
  const [assuredImage, setAssuredImage] = useState('/assets/trust.svg'); // Default to trust.svg

  useEffect(() => {
    // Fetch products
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${API_URL}/products`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    // Fetch homebanner assuredImage
    const fetchAssuredImage = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/assured_image`);
        const data = await response.json();
        const assuredImgFromAPI = data[0];
        if (assuredImgFromAPI) {
          setAssuredImage(assuredImgFromAPI);
        }
      } catch (error) {
        console.error('Error fetching assured image:', error);
      }
    };

    fetchProducts();
    fetchAssuredImage();
  }, []);

  if (!Array.isArray(categoryToShow)) {
    console.error('categoryToShow should be an array');
    return <p>Error: Invalid categoryToShow prop.</p>;
  }

  const normalizedCategoryToShow = categoryToShow.map(cat => cat.toLowerCase());
  const showAllProducts = normalizedCategoryToShow.includes('all');
  const filteredProducts = showAllProducts
    ? products
    : products.filter(product => {
        if (typeof product.category === 'string') {
          return normalizedCategoryToShow.includes(product.category.toLowerCase());
        }
        return false;
      });

  // Shuffle function to randomize product order
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };

  // Randomize the order of filtered products
  const randomizedProducts = shuffleArray([...filteredProducts]);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  return (
    <div className="products" id="products" style={{ marginBottom: '18px' }}>
      <h4 style={{ fontSize: '21px', color: 'Black' }} className="sc-gswNZR wDrko">
        Products For You
      </h4>
      <div className="product-list">
        {randomizedProducts.map((product) => (
          <Link
            key={product._id}
            to={`/productDetails/${product.id}`}
            className="product-card"
            data-id={product.id}
            onClick={scrollToTop} // Scroll to top on click
          >
            <div className="product-img">
              <img src={product.image} alt={product.name} />
            </div>
            <div className="product-details">
              <h3 className="product-name">{product.name}</h3>
              <div className="product-price">
                <span className="sell-price">₹{product.sellingPrice}</span>
                <span className="mrp-price line-through">₹{product.mrp}</span>
                <span className="off-percentage m-0">
                  {Math.round(((product.mrp - product.sellingPrice) / product.mrp) * 100)}% off
                </span>
              </div>
              <span className="NewProductCardstyled__OfferPill-sc-6y2tys-31 iMEkWH">
                <img src="/assets/rupee.svg" alt="rupee icon" className="sc-bcXHqe eqGISc" />
                <span fontSize="10px" color="greenBase" className="sc-gswNZR bjrKWS">
                  {product.offerText}
                </span>
              </span>
              <p className="free-delivery">Free Delivery</p>
              <div className="sc-kDvujY jTuxux NewProductCardstyled__RatingsRow-sc-6y2tys-8 heKerA" color="white">
                <div className="NewProductCardstyled__RatingSection-sc-6y2tys-9 fyvrGC">
                  <span label={product.rating} className="sc-fbYMXx jUvjLH">
                    <span fontSize="13px" fontWeight="demi" color="#ffffff" className="sc-gswNZR jrrvJf">
                      {product.rating}
                    </span>
                    <img src="/assets/star.svg" alt="star icon" className="sc-bcXHqe cyUZOu" />
                  </span>
                  <span fontSize="11px" fontWeight="book" color="greyT2" className="sc-gswNZR gTFgDk NewProductCardstyled__RatingCount-sc-6y2tys-21 jZyLzI">
                    ({product.ratingNumber})
                  </span>
                </div>
                {/* Use assuredImage fetched from homebanner or default to trust.svg */}
                <img
                  src={assuredImage || '/assets/trust.svg'}
                  alt="trust icon"
                  className="sc-bcXHqe fUjwpe"
                  style={{ width: '55px', height: '20px', objectFit: 'cover' }}
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Products;
