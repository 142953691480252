import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import API_URL from '../../apiconfig';

import './Cart.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Cart = () => {
    const navigate = useNavigate();
    const [cartProducts, setCartProducts] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);

    useEffect(() => {
        const fetchCartProducts = async () => {
            const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
            console.log(cartItems);

            if (cartItems.length > 0) {
                try {
                    const fetchedProducts = await Promise.all(
                        cartItems.map(async (item) => {
                            const response = await fetch(`${API_URL}/products/${item.productId}`);
                            const product = await response.json();
                            return {
                                ...product,
                                image: item.image,
                                variants: item.variants,
                                quantity: item.quantity
                            };
                        })
                    );
                    setCartProducts(fetchedProducts);
                    calculateCartTotal(fetchedProducts);
                } catch (error) {
                    console.error('Error fetching cart products:', error);
                }
            } else {
                setCartProducts([]);
                setCartTotal(0);
            }
        };

        fetchCartProducts();
    }, []);

    useEffect(() => {
        if (cartProducts.length === 0) {
            const timer = setTimeout(() => {
                navigate('/');
            }, 3000); // Wait for 3 seconds before redirecting

            return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, [cartProducts, navigate]);

    const handleDelete = (index) => {
        const updatedCartProducts = cartProducts.filter((_, i) => i !== index); // Filter out the deleted item
        const updatedCart = JSON.parse(localStorage.getItem('cart'))?.filter((_, i) => i !== index) || [];
    
        // Update localStorage with the new cart
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    
        // Update the cartProducts state
        setCartProducts(updatedCartProducts);
    
        // Recalculate cart total using updated cart products
        calculateCartTotal(updatedCartProducts);
    };
    

    const calculateCartTotal = (products) => {
        const total = products.reduce((sum, product) => sum + product.sellingPrice * product.quantity, 0);
        setCartTotal(total.toFixed(2));
    };

    return (
        <div style={{ minHeight: '110vh' }}>
            {/* Always Render Navigation */}
            <div className='check _1FWdmb'>
                <div className='lefty'>
                    <svg
            onClick={() => navigate(-1)}  // This navigates one step back in history
                        width="25"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        mt="2"
                        iconsize="24"
                        className="sc-gswNZR ffVWIj"
                        style={{ cursor: 'pointer' }}
                    >
                        <path
                            d="M13.7461 2.31408C13.5687 2.113 13.3277 2 13.0765 2C12.8252 2 12.5843 2.113 12.4068 2.31408L6.27783 9.24294C5.90739 9.66174 5.90739 10.3382 6.27783 10.757L12.4068 17.6859C12.7773 18.1047 13.3757 18.1047 13.7461 17.6859C14.1166 17.2671 14.0511 16.5166 13.7461 16.1718L8.29154 9.99462L13.7461 3.82817C13.9684 3.57691 14.1071 2.72213 13.7461 2.31408Z"
                            fill="#666666"
                        />
                    </svg>
                    <h6 className='header-title' style={{ fontFamily: 'Mier Book', margin: '0', width: '200px', fontSize: '13px' }}>
                        CART
                    </h6>
                </div>
            </div>
            {/* Always Render Stepper */}
            <div className="sc-bBXxYQ jMfCEJ"></div>
            <div data-testid="stepper-container" className="sc-geuGuN gqSLnX">
                <div className="sc-kGhOqx chtKwW">
                    <ul className="sc-bAKPPm eOmvaT">
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH lagJzQ"></div>
                                <div className="sc-dGHKFW cRaGaC">1</div>
                                <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                            </div>
                            <div className="sc-jWquRx iefUco">Cart</div>
                        </div>
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                                <div className="sc-dGHKFW iefbLi">2</div>
                                <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                            </div>
                            <div className="sc-jWquRx ezBHwi">Address</div>
                        </div>
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                                <div className="sc-dGHKFW iefbLi">3</div>
                                <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                            </div>
                            <div className="sc-jWquRx ezBHwi">Payment</div>
                        </div>
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                                <div className="sc-dGHKFW iefbLi">4</div>
                                <div data-testid="right-line" className="sc-jfdOKL jSyZxf"></div>
                            </div>
                            <div className="sc-jWquRx ezBHwi">Summary</div>
                        </div>
                    </ul>
                </div>
            </div>

            {/* Conditionally Render Cart Content */}
            {cartProducts.length === 0 ? (
                <div className="empty-cart-message" style={{ textAlign: 'center', marginTop: '25vh', marginBottom: '30vh' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 450.391 450.391">
                        <g>
                            <path d="M143.673 350.322c-25.969 0-47.02 21.052-47.02 47.02 0 25.969 21.052 47.02 47.02 47.02 25.969 0 47.02-21.052 47.02-47.02.001-25.968-21.051-47.02-47.02-47.02zm0 73.143c-14.427 0-26.122-11.695-26.122-26.122s11.695-26.122 26.122-26.122 26.122 11.695 26.122 26.122c.001 14.427-11.695 26.122-26.122 26.122zM342.204 350.322c-25.969 0-47.02 21.052-47.02 47.02 0 25.969 21.052 47.02 47.02 47.02s47.02-21.052 47.02-47.02c0-25.968-21.051-47.02-47.02-47.02zm0 73.143c-14.427 0-26.122-11.695-26.122-26.122s11.695-26.122 26.122-26.122 26.122 11.695 26.122 26.122c.001 14.427-11.695 26.122-26.122 26.122zM448.261 76.037a13.064 13.064 0 0 0-8.359-4.18L99.788 67.155 90.384 38.42C83.759 19.211 65.771 6.243 45.453 6.028H10.449C4.678 6.028 0 10.706 0 16.477s4.678 10.449 10.449 10.449h35.004a27.17 27.17 0 0 1 25.078 18.286l66.351 200.098-5.224 12.016a50.154 50.154 0 0 0 4.702 45.453 48.588 48.588 0 0 0 39.184 21.943h203.233c5.771 0 10.449-4.678 10.449-10.449s-4.678-10.449-10.449-10.449H175.543a26.646 26.646 0 0 1-21.943-12.539 28.733 28.733 0 0 1-2.612-25.078l4.18-9.404 219.951-22.988c24.16-2.661 44.034-20.233 49.633-43.886L449.83 84.917a8.882 8.882 0 0 0-1.569-8.88zm-43.885 109.191c-3.392 15.226-16.319 26.457-31.869 27.69l-217.339 22.465-48.588-147.33 320.261 4.702-22.465 92.473z" fill="#cccccc" />
                        </g>
                    </svg>
                    <p style={{ fontSize: '20px', fontWeight: '600', color: '#1a2024', marginTop: '3rem', marginBottom: '40px' }}>
                        Your cart is feeling lonely
                    </p>
                </div>
            ) : (
                <>
<div className="card" style={{ borderRadius: '0' }}>
                        <div className="cart-products-list cart-list">
                            {cartProducts.length > 0 ? (
                                cartProducts.map((product, index) => (
                                    <div key={product.id} className={`cart-product cart-product-index-${index}`}>
                                        <div className="cart-product-img">
                                            <img src={product.image} alt={product.name} />
                                        </div>
                                        <div className="cart-product-details">
                                            <div className="cart-product-title">
                                                <p>{product.name}</p>
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/0057/8938/4802/files/Group_1_93145e45-8530-46aa-9fb8-6768cc3d80d2.png?v=1633783107"
                                                    className="remove-cart-item"
                                                    onClick={() => handleDelete(index)}
                                                    alt="delete"
                                                />
                                            </div>
                                            <div className="cart-product-pricing">
                                                <p className="cart-product-price">₹{product.sellingPrice}</p>&nbsp;
                                                <span className="cart-product-mrp">₹{product.mrp}</span>
                                            </div>
                                            <div className="cart-product-description">
                                            <div className="cart-product-description">
  {Object.entries(product.variants).map(([key, value]) => (
    <div key={key}>
      <p className="cart-product-color" style={{fontFamily:'Mier Demi'}}>
        <strong>{capitalizeFirstLetter(key)}:</strong>{' '}
        {Array.isArray(value) ? (
          value.join(', ') // Join array values with a comma for a clean display
        ) : (
          <span>{value}</span>
        )}
      </p>
    </div>
  ))}
</div>
                                                <span className="sc-lbxAil evmCQI"></span>
                                                <span className="cart-qty">Qty: {product.quantity}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>Your cart is empty.</p>
                            )}
                        </div>
                        <div className="cart__footer cart_page_footer">
                            <div className="cart__price__details">
                                <div className="cart__breakup__inner">
                                    <div className="shipping__total">
                                        <span className="">Shipping:</span>
                                        <span className="">FREE</span>
                                    </div>
                                    <div className="cart__total">
                                        <span className="">Total Product Price:</span>
                                        <span className="cartTotalAmount">₹{cartTotal}</span>
                                    </div>
                                    <div className="sc-bBXxYQ jMfCEJ mt-1 mb-1"></div>
                                    <div className="mc_pay__total">
                                        <span className="">Order Total:</span>
                                        <span className="cartTotalAmount">₹{cartTotal}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sefty-banner">
                        <img style={{marginBottom:'60px'}} src="https://images.meesho.com/images/marketing/1588578650850.webp" alt="safe" className="w-100" />
                    </div>
                    <div className="button-containerzz flex">
                        <div className="sc-fThYeS dUijPM">
                            <span className="cartTotalAmount">₹{cartTotal}</span>
                            <button className="sc-kLLXSd ylmAj">VIEW PRICE DETAILS</button>
                        </div>
                        <div className="sc-BrFsL iAFVK">
                        <Link to="/address" state={{ totalPrice: cartTotal }}>
    <button className="sc-ikZpkk bwHzRF">Continue</button>
</Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  

export default Cart;
