import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import './Images.css'; // Import your custom CSS
import Products from '../../Home/Products/Products';
import Buttons from '../Buttons/Buttons'
import { useParams } from 'react-router-dom'; // Import useParams
import API_URL from '../../../apiconfig';



const Images = () => {
  const { id } = useParams(); // Get the product ID from the URL

  const [activeIndex, setActiveIndex] = useState(0);
  const [colorList, setColorList] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [activeColorImage, setActiveColorImage] = useState('');
  const [productDetails, setProductDetails] = useState({});
  const [activeVariants, setActiveVariants] = useState({});

  const handleColorClick = (index) => {
    setActiveIndex(index);
    setActiveColorImage(colorList[index].image); // Update the carousel image based on selected color
  };

  const handleVariantClick = (variantType, variantValue) => {
    setActiveVariants((prev) => ({
      ...prev,
      [variantType]: variantValue, // Set the active value for the clicked variant type
    }));
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(`${API_URL}/products/${id}`);
        const data = await response.json();

        // Assuming the API returns a single product object
        if (data) {
          setProductDetails(data);
          setColorList(data.colors || []);
          setActiveColorImage(data.colors[0]?.image || ''); // Set the initial active color image
          console.log(data.CategoryToShow)
          // Process variant array
          if (data.variant && data.variant.length > 0) {
            setVariantData(data.variant);
          }
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [id]); // Include id in dependency array

  const calculateDiscount = (mrp, sellingPrice) => {
    return Math.round(((mrp - sellingPrice) / mrp) * 100);
  };
  return (
    <div className="detailscover">
      <div className="container-fluid px-0 product-slider" style={{ backgroundColor: 'White' }}>
        <div id="sliderX" className="carousel slide pointer-event" data-bs-ride="carousel">
          <ol className="carousel-indicators">
            <li className="active" data-bs-target="#sliderX" data-bs-slide-to="0"></li>
          </ol>
          <div className="carousel-inner">
            {activeColorImage && (
              <div className="carousel-item active">
                <img className="d-block w-100 main_image slider-item-1" src={activeColorImage} alt="Active Imageaaa" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="color-div" style={{ backgroundColor: 'white' }}>
          <h4>{colorList.length} Similar Products</h4>
          <div className="color-list p-2">
            {colorList.map((color, index) => (
              <div
                key={index}
                className={`color-box color-item p-2 me-2 ${index === activeIndex ? 'active' : ''}`}
                data-mrp={productDetails.mrp}
                data-selling-price={productDetails.sellingPrice }
                onClick={() => handleColorClick(index)}
              >
                <img
                  src={color.image}
                  alt={`img${index}`}
                  className="color_img_0"
                />
                <span className="color-name d-none">{color.name}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Dynamic Product Information */}
        <div className="container-fluid pt-4" style={{ backgroundColor: 'white' }}>
          <div className="product-title-div">
            <div className="product-title" data-id={productDetails.id}>
              {productDetails.name}
            </div>
            <div className="ProductWishListIconstyled__ProductWishList-sc-1clzx80-1 gNlzBX">
              <div className="ProductWishListIconstyled__WishListIcon-sc-1clzx80-3 bdcVUy">
                <img
                  src="/assets/wishlist.svg"
                  width="16"
                  height="16"
                  alt="Wishlist Icon"
                  className="sc-gswNZR ProductWishListIconstyled__ProductWishListImage-sc-1clzx80-0 kwsBSS iZRFqd"
                />
              </div>
              <span
                style={{ fontSize: "small", fontWeight: "book", color: "greyBase" }}
                className="sc-dkrFOg gTFoAs ProductWishListIconstyled__Text1-sc-1clzx80-2 dyZkZi"
              >
                Wishlist
              </span>
            </div>
            <div className="ProductShareIconstyles__ProductShare-sc-uxw4hm-0 cDBOnq">
              <div className="ProductShareIconstyles__ShareIcon-sc-uxw4hm-2 clikdT">
                <img
                  src="/assets/share.svg"
                  width="17"
                  height="17"
                  alt="Share Icon"
                  className="sc-gswNZR juWHgk"
                />
              </div>
              <span
                style={{ fontSize: "small", fontWeight: "book", color: "greyBase" }}
                className="sc-dkrFOg gTFoAs ProductShareIconstyles__Text1-sc-uxw4hm-1 cHjLGv"
              >
                Share
              </span>
            </div>
          </div>
          <div className="product-price d-flex my-2">
            <span className="price" data-price={productDetails.sellingPrice}>
              ₹{productDetails.sellingPrice}
            </span>
            <span className="mrp" data-mrp={productDetails.mrp}>
              ₹{productDetails.mrp}
            </span>
            <span className="discount">
              {calculateDiscount(productDetails.mrp, productDetails.sellingPrice)}% off
            </span>
          </div>

          {/* Dynamic Offer Section */}
          {productDetails.offerText && (
            <span className="Offersstyled__OfferWrapper-sc-1ng6jp-0 hdHcbs">
              <span
                style={{ fontSize: "12px", fontFamily: 'Mier Book', color: "greenBase" }}
                className="sc-ipEyDJ gHgHGw"
              >
                ₹{productDetails.offerText}
              </span>
              <img
                src="/assets/arrow.svg"
                width="16"
                height="16"
                alt="Offer Icon"
                className="sc-gswNZR EaPUQ"
              />
            </span>
          )}

          {/* Ratings and Assured Image */}
          <div className="aMaAEs">
            <div className="_3Zuayz mt-2">
              <div className="_3_L3jD">
                <div className="gUuXy- _16VRIQ _1eJXd3">
                  <span id="productRating_LSTETHFZZUWAC8X2PGQZ7T8VQ_ETHFZZUWAC8X2PGQ_" className="_1lRcqv">
                    <div className="_3LWZlK _3uSWvT">
                      {productDetails.rating}
                      <img
                        src="/assets/star.svg"
                        alt="Star Icon"
                        className="_1wB99o _19cuvx"
                      />
                    </div>
                  </span>
                  <span className="_2_R_DZ">
                    <span>{productDetails.ratingText} </span>
                  </span>
                </div>
              </div>
              <span className="ShippingInfoMobilestyles__Dot-sc-b8wrmp-7 bxhscH"></span>
              <span className="b7864- _2Z07dN">
  <img
    src={productDetails.assuredImage && productDetails.assuredImage.trim() !== "" 
          ? productDetails.assuredImage 
          : "/assets/trust.svg"}
    width="55"
    height="20"
    alt="Assured"
    className="sc-gswNZR eCiixe"
  />
</span>

            </div>

            {/* Free Delivery */}
            <div className="sc-dkrFOg kdtyUz DeliveryBadge__BadgeRow-sc-skvcwk-4 cYgzqn pb-4">
              <div className="sc-hhOBVt evBRxL">
                <span
                  style={{ fontSize: "12px", color: "black", fontFamily: 'Mier Book' }}
                  className="sc-jrcTuL eXZucf"
                >
                  Free Delivery
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      {/* Display the variant options */}
      {variantData.map((variant, index) => (
  <div className="storage-div " key={index}>
    {/* Mapping over the variant object */}
    {Object.keys(variant).map((key) => {
      // Skip 'colors' field if it exists
      if (key === 'colors') return null;

      // Check if the key is 'key' and map over its corresponding 'value' array
      if (key === 'key') {
        return Array.isArray(variant['value']) && variant['value'].length > 0 ? (
          <div
            key={key}
            className="sc-kDvujY iGlGaV SizeSelectionstyled__SizeCard-sc-155vsje-0 eeldyg"
            style={{ color: "white" }}
          >
            <h6
              style={{ fontSize: "17px", fontWeight: "bold", color: "greyBase" }}
              className="sc-gswNZR hmRtgo SizeSelectionstyled__Headline6Styled-sc-155vsje-1 keaMtN"
            >
              {`Select ${variant[key].charAt(0).toUpperCase() + variant[key].slice(1)}`}
            </h6>
            <div className="sc-kImNAt hnbNOX SizeSelectionstyled__SizeSelectorChipsStyled-sc-155vsje-2 dEkkqK">
              {variant['value'].map((item) => (
                <span
                  key={item}
                  className={`sc-eKJbhj dqLmGj dress-size ${activeVariants[variant[key]] === item ? 'active' : ''}`}
                  onClick={() => handleVariantClick(variant[key], item)}
                >
                  <span
                    style={{ fontSize: "15px", color: "greyBase", fontFamily: 'Mier Book' }}
                    className="sc-gswNZR iwPwcX"
                  >
                    {item}
                  </span>
                </span>
              ))}
            </div>
          </div>
        ) : null;
      }

      return null; // Return null if key is not found
    })}
  </div>
))}
  


        
      <div className="container-fluid product-detail mb-4 card" style={{ marginTop: '3px', padding: '20px 16px', borderRadius: '2px' }}>
  <h6 style={{ fontSize: '17px', fontFamily: 'Mier Book', color: 'greyBase', fontWeight: '700' }} className="sc-hBxehG jvhzrN">
    Product Details
  </h6>
  <div className="product-details" dangerouslySetInnerHTML={{ __html: productDetails.description || '' }}></div>
</div>

<Products categoryToShow={productDetails.CategoryToShow} />
<Buttons productId={productDetails.id} selectedVariants={activeVariants} activeImage={activeColorImage} />

    
    </div></div>
  );
};

export default Images;
