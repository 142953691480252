import React, { useEffect, useState } from 'react';
import './Banner.css';
import Carousel from './Carousels/Carousel';
import API_URL from '../../../apiconfig';

const Banner = () => {
  const [banner1Link, setBanner1Link] = useState('https://images.meesho.com/images/widgets/Y8HIH/2f53o.gif'); // Set initial state to null
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/banner1_link`); // Fetching the specific field directly
        const data = await response.json();
        
        if (data.length > 0) {
          setBanner1Link(data[0]); // Directly setting the link
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetch attempt
      }
    };

    fetchBanner();
  }, []);

  // Render nothing or a loading indicator while the data is being fetched
  if (loading) {
    return null; // Or you can return a loading spinner/component if desired
  }

  return (
    <div>
      {banner1Link && ( // Only render the image if banner1Link is not null
        <img 
          src={banner1Link} 
          alt="Banner" 
          className="w-100 mb-2" 
          style={{ width: '375px', height: '38px', objectFit: 'cover' }} 
        />
      )}
      <Carousel />
    </div>
  );
}

export default Banner;
