import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import API_URL from '../../apiconfig';
import './Payment.css';

const Payment = () => {
    const [paymentOptions, setPaymentOptions] = useState({});
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [upiId, setUpiId] = useState('');
    const [upiName, setUpiName] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { totalPrice } = location.state || { totalPrice: 0 };
    const price = Number(totalPrice); // Convert to number

    useEffect(() => {
        const fetchPaymentOptions = async () => {
            try {
                const response = await fetch(`${API_URL}/upi`);
                const data = await response.json();
                if (data.length > 0) {
                    setPaymentOptions(data[0].payment_options);
                    setUpiId(data[0].upi_id);
                    setUpiName(data[0].upi_name);
                }
            } catch (error) {
                console.error('Error fetching payment options:', error);
            }
        };

        fetchPaymentOptions();
    }, []);

    const handlePayment = () => {
        if (!selectedPayment) {
            console.log('Select a payment method');
            return;
        }

        const transactionId = 'H2MkMGf5olejI'; // Replace with actual transaction ID
        const merchantCode = '8931'; // Replace with actual merchant code
        const currency = 'INR';
        const note = 'Online Shopping';
        const amount = price.toFixed(2);
        const signature = 'AAuN7izDWN5cb8A5scnUiNME+LkZqI2DWgkXlN1McoP6WZABa/KkFTiLvuPRP6/nWK8BPg/rPhb+u4QMrUEX10UsANTDbJaALcSM9b8Wk218X+55T/zOzb7xoiB+BcX8yYuYayELImXJHIgL/c7nkAnHrwUCmbM97nRbCVVRvU0ku3Tr'; // Replace with actual signature

        if (selectedPayment === 'gpay') {
            // Google Pay logic
            openGooglePay(amount, transactionId, merchantCode, note);
        } else if (selectedPayment === 'paytm') {
            const upiAddress = upiId; // Using the fetched UPI ID
            if (upiAddress && amount) {
                // Construct the Paytm URL
                const paytmLink = `paytmmp://cash_wallet?pa=${upiAddress}&pn=${upiName}&mc=${merchantCode}&tr=${transactionId}&tn=${note}&am=${amount}&cu=${currency}&url=&mode=02&purpose=00&orgid=159002&sign=${signature}&featuretype=money_transfer`;
                
                // Redirect to the Paytm app
                window.location.href = paytmLink;
            } else {
                alert('Amount or UPI ID missing!');
            }
        } else {
            // Handle other payment methods as before
            let paymentLink = '';
            switch (selectedPayment) {
                case 'phonepe':
                    paymentLink = `phonepe://pay?pa=${upiId}&pn=${upiName}&am=${amount}&cu=${currency}&tr=${transactionId}&tn=${note}&sign=${signature}`;
                    break;
                case 'bhim_upi':
                    paymentLink = `upi://pay?pa=${upiId}&pn=${upiName}&am=${amount}&cu=${currency}&tr=${transactionId}&tn=${note}&sign=${signature}`;
                    break;
                default:
                    console.log('Select a payment method');
                    return;
            }
            window.location.href = paymentLink;
        }
    };
    const openGooglePay = (amount, transactionId, merchantCode, note) => {
        if (amount > 0) {
            if (!window.PaymentRequest) {
                console.log('Web payments are not supported in this browser.');
                return;
            }

            // Create supported payment method.
            const supportedInstruments = [
                {
                    supportedMethods: ['https://tez.google.com/pay'],
                    data: {
                        pa: upiId,  // Using the fetched UPI ID
                        pn: upiName,  // Using the fetched UPI name
                        tr: transactionId,
                        url: 'https://yourwebsite.com/order/' + transactionId,  // URL of the order
                        mc: merchantCode,
                        tn: note,
                    },
                },
            ];

            // Create order detail data.
            const details = {
                total: {
                    label: 'Total',
                    amount: {
                        currency: 'INR',
                        value: amount, // Amount to be paid
                    },
                },
                displayItems: [{
                    label: 'Original Amount',
                    amount: {
                        currency: 'INR',
                        value: amount,
                    },
                }],
            };

            // Create payment request object.
            let request = null;
            try {
                request = new PaymentRequest(supportedInstruments, details);
            } catch (e) {
                console.log('Payment Request Error: ' + e.message);
                return;
            }
            if (!request) {
                console.log('Web payments are not supported in this browser.');
                return;
            }

            // Show the payment UI
            request.show()
                .then(function (instrument) {
                    // Process the payment
                    console.log('Payment successful', instrument);
                    instrument.complete('success');
                    // Navigate to success page or perform further actions
                })
                .catch(function (err) {
                    console.log('Payment failed', err);
                });
        }
    };


    return (
        <div>
            <div className='check _1FWdmb'>
                <div className='lefty'>
                    <svg
                        onClick={() => navigate(-1)}
                        width="25"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        mt="2"
                        iconsize="24"
                        className="sc-gswNZR ffVWIj"
                        style={{ cursor: 'pointer' }}
                    >
                        <path
                            d="M13.7461 2.31408C13.5687 2.113 13.3277 2 13.0765 2C12.8252 2 12.5843 2.113 12.4068 2.31408L6.27783 9.24294C5.90739 9.66174 5.90739 10.3382 6.27783 10.757L12.4068 17.6859C12.7773 18.1047 13.3757 18.1047 13.7461 17.6859C14.1166 17.2671 14.0511 16.5166 13.7461 16.1718L8.29154 9.99462L13.7461 3.82817C13.9684 3.57691 14.1071 2.72213 13.7461 2.31408Z"
                            fill="#666666"
                        />
                    </svg>
                    <h6 className='header-title' style={{ fontFamily: 'Mier Book', margin: '0', width: '200px', fontSize: '13px' }}>
                        ADD DELIVERY ADDRESS
                    </h6>
                </div>
            </div>
            <div className="sc-bBXxYQ jMfCEJ"></div>
            <div data-testid="stepper-container" className="sc-geuGuN gqSLnX">
                <div className="sc-kGhOqx chtKwW">
                    <ul className="sc-bAKPPm eOmvaT">
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH lagJzQ"></div>
                                <div className="sc-iJkHyd IhlWp">
                                    <svg width="20" height="20" viewBox="0.5 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <title>check-icon</title>
                                        <rect x="1.25" y="0.75" width="18.5" height="18.5" rx="9.25" fill="#5585F8"></rect>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.6716 7.37285C17.0971 6.96439 17.1108 6.28832 16.7023 5.86289C16.2939 5.43755 15.618 5.4238 15.1926 5.83218L10.9997 9.85723L10.9997 9.85727L9.02229 11.7557L6.82333 9.55674C6.40622 9.13963 5.72995 9.13963 5.31284 9.55674C4.8957 9.97388 4.89573 10.6502 5.31289 11.0673L8.26525 14.0192C8.66883 14.4227 9.32103 14.4293 9.73274 14.0341L10.9998 12.8178V12.8178L16.6716 7.37285Z" fill="white"></path>
                                        <rect x="1.25" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#5585F8" strokeWidth="1.5"></rect>
                                    </svg>
                                </div>
                                <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                            </div>
                            <div className="sc-jWquRx ezBHwi">Cart</div>
                        </div>
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                                <div className="sc-iJkHyd IhlWp">
                                    <svg width="20" height="20" viewBox="0.5 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <title>check-icon</title>
                                        <rect x="1.25" y="0.75" width="18.5" height="18.5" rx="9.25" fill="#5585F8"></rect>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.6716 7.37285C17.0971 6.96439 17.1108 6.28832 16.7023 5.86289C16.2939 5.43755 15.618 5.4238 15.1926 5.83218L10.9997 9.85723L10.9997 9.85727L9.02229 11.7557L6.82333 9.55674C6.40622 9.13963 5.72995 9.13963 5.31284 9.55674C4.8957 9.97388 4.89573 10.6502 5.31289 11.0673L8.26525 14.0192C8.66883 14.4227 9.32103 14.4293 9.73274 14.0341L10.9998 12.8178V12.8178L16.6716 7.37285Z" fill="white"></path>
                                        <rect x="1.25" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#5585F8" strokeWidth="1.5"></rect>
                                    </svg>
                                </div>
                                <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                            </div>
                            <div className="sc-jWquRx ezBHwi">Address</div>
                        </div>
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                                <div className="sc-dGHKFW cRaGaC">3</div>
                                <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                            </div>
                            <div className="sc-jWquRx iefUco">Payment</div>
                        </div>
                        <div className="sc-jZiqTT hGoFZP">
                            <div className="sc-bxSTMQ geeMAN">
                                <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                                <div className="sc-dGHKFW iefbLi">4</div>
                                <div data-testid="right-line" className="sc-jfdOKL jSyZxf"></div>
                            </div>
                            <div className="sc-jWquRx ezBHwi">Summary</div>
                        </div>
                    </ul>
                </div>
            </div>
            <div className="sc-bBXxYQ jMfCEJ"></div>
            <div className='wrap'>
                <div className="sc-dwVMhp gNFCeh">
                    <h6 style={{ fontSize: "17px", fontWeight: "bold", color: "greyBase" }} className="sc-fnykZs hEBjyt">
                        Select Payment Method
                    </h6>
                    <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.1172 3C10.3409 3 9.04382 3.29813 7.82319 3.63C6.57444 3.9675 5.31557 4.36687 4.57532 4.60875C4.26582 4.71096 3.99143 4.8984 3.78367 5.14954C3.57591 5.40068 3.44321 5.70533 3.40082 6.0285C2.73032 11.0651 4.28619 14.7979 6.17394 17.2672C6.97447 18.3236 7.92897 19.2538 9.00557 20.0269C9.43982 20.334 9.84257 20.5691 10.1845 20.73C10.4995 20.8785 10.8382 21 11.1172 21C11.3962 21 11.7337 20.8785 12.0498 20.73C12.4621 20.5296 12.8565 20.2944 13.2288 20.0269C14.3054 19.2538 15.2599 18.3236 16.0604 17.2672C17.9482 14.7979 19.504 11.0651 18.8335 6.0285C18.7912 5.70518 18.6586 5.40035 18.4508 5.14901C18.2431 4.89768 17.9686 4.71003 17.659 4.60762C16.5845 4.25529 15.5015 3.92894 14.4112 3.62888C13.1905 3.29925 11.8934 3 11.1172 3ZM13.5314 9.68925C13.637 9.58363 13.7803 9.52429 13.9297 9.52429C14.079 9.52429 14.2223 9.58363 14.3279 9.68925C14.4335 9.79487 14.4929 9.93813 14.4929 10.0875C14.4929 10.2369 14.4335 10.3801 14.3279 10.4858L10.9529 13.8608C10.9007 13.9131 10.8386 13.9547 10.7703 13.9831C10.7019 14.0114 10.6287 14.026 10.5547 14.026C10.4807 14.026 10.4074 14.0114 10.3391 13.9831C10.2707 13.9547 10.2087 13.9131 10.1564 13.8608L8.46894 12.1733C8.41664 12.121 8.37516 12.0589 8.34685 11.9905C8.31855 11.9222 8.30398 11.849 8.30398 11.775C8.30398 11.701 8.31855 11.6278 8.34685 11.5595C8.37516 11.4911 8.41664 11.429 8.46894 11.3767C8.52124 11.3244 8.58333 11.283 8.65166 11.2547C8.71999 11.2264 8.79323 11.2118 8.86719 11.2118C8.94115 11.2118 9.01439 11.2264 9.08272 11.2547C9.15105 11.283 9.21314 11.3244 9.26544 11.3767L10.5547 12.6671L13.5314 9.68925Z" fill="#ADC6FF"></path>
                    <path d="M24.1172 3.53998L24.2472 4.65998L26.0372 3.67998V9.49998H27.1472V2.49998H26.1472L24.1172 3.53998Z" className="fill-grey-t2"></path>
                    <path d="M31.5958 9.64998C33.2058 9.64998 34.2458 8.19998 34.2458 5.99998C34.2458 3.79998 33.2058 2.34998 31.5658 2.34998C29.9458 2.34998 28.9158 3.79998 28.9158 5.99998C28.9158 8.19998 29.9458 9.64998 31.5958 9.64998ZM31.5958 8.62998C30.5958 8.62998 30.0658 7.55998 30.0658 5.99998C30.0658 4.43998 30.5858 3.36998 31.5658 3.36998C32.5658 3.36998 33.0958 4.43998 33.0958 5.99998C33.0958 7.55998 32.5658 8.62998 31.5958 8.62998Z" className="fill-grey-t2"></path>
                    <path d="M38.149 9.64998C39.759 9.64998 40.799 8.19998 40.799 5.99998C40.799 3.79998 39.759 2.34998 38.119 2.34998C36.499 2.34998 35.469 3.79998 35.469 5.99998C35.469 8.19998 36.499 9.64998 38.149 9.64998ZM38.149 8.62998C37.149 8.62998 36.619 7.55998 36.619 5.99998C36.619 4.43998 37.139 3.36998 38.119 3.36998C39.119 3.36998 39.649 4.43998 39.649 5.99998C39.649 7.55998 39.119 8.62998 38.149 8.62998Z" className="fill-grey-t2"></path>
                    <path d="M43.4923 6.24998C44.3823 6.24998 45.0923 5.55998 45.0923 4.29998C45.0923 3.03998 44.3823 2.34998 43.4923 2.34998C42.6123 2.34998 41.9023 3.03998 41.9023 4.29998C41.9023 5.55998 42.6123 6.24998 43.4923 6.24998ZM48.2923 2.49998H47.4323L42.7823 9.49998H43.6423L48.2923 2.49998ZM43.4923 5.43998C43.0623 5.43998 42.7623 5.06998 42.7623 4.29998C42.7623 3.52998 43.0623 3.15998 43.4923 3.15998C43.9223 3.15998 44.2323 3.52998 44.2323 4.29998C44.2323 5.06998 43.9223 5.43998 43.4923 5.43998ZM47.5823 9.64998C48.4723 9.64998 49.1823 8.95998 49.1823 7.69998C49.1823 6.43998 48.4723 5.74998 47.5823 5.74998C46.7023 5.74998 45.9923 6.43998 45.9923 7.69998C45.9923 8.95998 46.7023 9.64998 47.5823 9.64998ZM47.5823 8.83998C47.1523 8.83998 46.8523 8.46998 46.8523 7.69998C46.8523 6.92998 47.1523 6.55998 47.5823 6.55998C48.0223 6.55998 48.3223 6.92998 48.3223 7.69998C48.3223 8.46998 48.0223 8.83998 47.5823 8.83998Z" className="fill-grey-t2"></path>
                    <path d="M55.4541 9.64998C56.9141 9.64998 57.9341 8.78998 57.9341 7.47998C57.9341 4.79998 54.3341 5.96998 54.3341 4.29998C54.3341 3.69998 54.8141 3.34998 55.4641 3.34998C56.2141 3.34998 56.6841 3.78998 56.7941 4.42998L57.9041 4.22998C57.7241 3.12998 56.8241 2.34998 55.4941 2.34998C54.1441 2.34998 53.1741 3.16998 53.1741 4.39998C53.1741 7.07998 56.7641 5.90998 56.7641 7.59998C56.7641 8.22998 56.2541 8.65998 55.4841 8.65998C54.7941 8.65998 54.1341 8.28998 54.0141 7.57998L52.9041 7.81998C53.1041 8.98998 54.2041 9.64998 55.4541 9.64998Z" className="fill-grey-t2"></path>
                    <path d="M65.2964 9.49998L62.6764 2.45998H61.4064L58.7864 9.49998H59.9964L60.6264 7.68998H63.4264L64.0664 9.49998H65.2964ZM62.0564 3.73998L63.0864 6.65998H60.9964L62.0164 3.73998H62.0564Z" className="fill-grey-t2"></path>
                    <path d="M71.3322 2.49998H66.7522V9.49998H67.8922V6.54998H70.4722V5.50998H67.8922V3.53998H71.3322V2.49998Z" className="fill-grey-t2"></path>
                    <path d="M77.6917 2.49998H73.0417V9.49998H77.6917V8.45998H74.1817V6.49998H76.8417V5.44998H74.1817V3.53998H77.6917V2.49998Z" className="fill-grey-t2"></path>
                    <path d="M27.307 18.9C28.657 18.9 29.627 17.95 29.627 16.7C29.627 15.33 28.537 14.5 27.207 14.5H24.717V21.5H25.857V18.9H27.307ZM28.447 16.7C28.447 17.31 28.027 17.85 27.117 17.85H25.857V15.55H27.007C28.037 15.55 28.447 16.11 28.447 16.7Z" className="fill-grey-t2"></path>
                    <path d="M36.4524 21.5L33.8324 14.46H32.5624L29.9424 21.5H31.1524L31.7824 19.69H34.5824L35.2224 21.5H36.4524ZM33.2124 15.74L34.2424 18.66H32.1524L33.1724 15.74H33.2124Z" className="fill-grey-t2"></path>
                    <path d="M39.6613 21.5V18.57L42.0913 14.5H40.8413L39.1513 17.45H39.1013L37.4113 14.5H36.0813L38.5213 18.57V21.5H39.6613Z" className="fill-grey-t2"></path>
                    <path d="M50.8513 21.5V14.5H49.1513L47.1513 19.76H47.1113L45.1213 14.5H43.3513V21.5H44.4913V15.98H44.5313L46.6113 21.5H47.5913L49.6713 15.98H49.7113V21.5H50.8513Z" className="fill-grey-t2"></path>
                    <path d="M57.5103 14.5H52.8604V21.5H57.5103V20.46H54.0004V18.5H56.6604V17.45H54.0004V15.54H57.5103V14.5Z" className="fill-grey-t2"></path>
                    <path d="M65.3439 21.54V14.5H64.2039V19.78L60.5339 14.46H59.2639V21.5H60.4039V16.22L64.0739 21.54H65.3439Z" className="fill-grey-t2"></path>
                    <path d="M72.0671 14.5H66.7571V15.55H68.8471V21.5H69.9871V15.55H72.0671V14.5Z" className="fill-grey-t2"></path>
                    <path d="M75.4028 21.65C76.8628 21.65 77.8828 20.79 77.8828 19.48C77.8828 16.8 74.2828 17.97 74.2828 16.3C74.2828 15.7 74.7628 15.35 75.4128 15.35C76.1628 15.35 76.6328 15.79 76.7428 16.43L77.8528 16.23C77.6728 15.13 76.7728 14.35 75.4428 14.35C74.0928 14.35 73.1228 15.17 73.1228 16.4C73.1228 19.08 76.7128 17.91 76.7128 19.6C76.7128 20.23 76.2028 20.66 75.4328 20.66C74.7428 20.66 74.0828 20.29 73.9628 19.58L72.8528 19.82C73.0528 20.99 74.1528 21.65 75.4028 21.65Z" className="fill-grey-t2"></path>
                </svg>
                </div>
                <div className="sc-hKdnnL cHsEym">
                    <div className="sc-liHMlC efQsfx">
                        <video
                            autoPlay
                            loop
                            playsInline
                            src="/assets/cod_lat.webm"
                            className="sc-fSUSjI eVDQPI"
                        ></video>
                        <div className="sc-cuqtlR cOCnuI">
                            <span
                                style={{ fontSize: "15px", fontWeight: "600", color: "pinkBase" }}
                                className="sc-fnykZs eNkLGR sc-bTmccw RrifI sc-bTmccw RrifI"
                            >
                                Pay online &amp; get EXTRA ₹33 off
                            </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div data-testid="PAY ONLINE" className="sc-gVAlfg GmPbS">
                                <span>PAY ONLINE</span>
                                <div></div>
                            </div>
                            <ul className="accordion">
                                <li className="accordion-item is-active">
                                    <h3 className="accordion-thumb">
                                        <div className="flex align-items-center">
                                            <img src="https://images.meesho.com/files/headless/upi_ppr.png" width="20px" alt="UPI payment options" />
                                            <span className="order-summary px-2">UPI(GPay/PhonePe/Paytm)</span>
                                        </div>
                                    </h3>
                                    <div className="accordion-panel p-0 pt-3" style={{ display: 'block' }}>
                                        <div className="plans">
                                            {paymentOptions.divgooglepay && (
                                                <label className="plan" htmlFor="gpay">
                                                    <input
                                                        type="radio"
                                                        id="gpay"
                                                        name="paymentMethod"
                                                        className="paymentMethod"
                                                        value="gpay"
                                                        onChange={() => setSelectedPayment('gpay')}
                                                    />
                                                    <div className="plan-content">
                                                        <div className="plan-details">
                                                            <span>G Pay</span>
                                                            <img src="/assets/gpay_icon.svg" className="pay-logo" alt="G Pay" />
                                                        </div>
                                                    </div>
                                                </label>
                                            )}
                                            {paymentOptions.divphonepe && (
                                                <label className="plan" htmlFor="phonepe">
                                                    <input
                                                        type="radio"
                                                        id="phonepe"
                                                        name="paymentMethod"
                                                        className="paymentMethod"
                                                        value="phonepe"
                                                        onChange={() => setSelectedPayment('phonepe')}
                                                    />
                                                    <div className="plan-content">
                                                        <div className="plan-details">
                                                            <span>PhonePe</span>
                                                            <img src="/assets/phonepe.svg" className="pay-logo" alt="PhonePe" />
                                                        </div>
                                                    </div>
                                                </label>
                                            )}
                                            {paymentOptions.divupi && (
                                                <label className="plan" htmlFor="paytm">
                                                    <input
                                                        type="radio"
                                                        id="paytm"
                                                        name="paymentMethod"
                                                        className="paymentMethod"
                                                        value="paytm"
                                                        onChange={() => setSelectedPayment('paytm')}
                                                    />
                                                    <div className="plan-content">
                                                        <div className="plan-details">
                                                            <span>Paytm</span>
                                                            <img src="/assets/paytm_icon.svg" className="pay-logo" alt="Paytm" />
                                                        </div>
                                                    </div>
                                                </label>
                                            )}
                                            {paymentOptions.divbhimupi && (
                                                <label className="plan" htmlFor="bhim_upi">
                                                    <input
                                                        type="radio"
                                                        id="bhim_upi"
                                                        name="paymentMethod"
                                                        className="paymentMethod"
                                                        value="bhim_upi"
                                                        onChange={() => setSelectedPayment('bhim_upi')}
                                                    />
                                                    <div className="plan-content">
                                                        <div className="plan-details">
                                                            <span>BHIM UPI</span>
                                                            <img src="/assets/bhim_upi.svg" className="pay-logo" alt="BHIM UPI" />
                                                        </div>
                                                    </div>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="cart__footers">
                    <div className="cart__price__details">
                        <div className="cart__breakup__inner">
                            <div className="shipping__total" style={{ fontFamily: 'Mier Book' }}>
                                <span>Shipping:</span>
                                <span>FREE</span>
                            </div>
                            <div className="cart__total">
                                <span>Total Product Price:</span>
                                <span className="cartTotalAmount">₹{totalPrice}</span>
                            </div>
                            <div className="sc-bBXxYQ jMfCEJ mt-3 mb-1"></div>
                            <div className="mc_pay__total">
                                <span>Order Total :</span>
                                <span className="cartTotalAmount">₹{totalPrice}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-container flex">
                    <div className="sc-fThYeS dUijPM">
                        <span className="cartTotalAmount">₹{totalPrice}</span>
                        <button className="sc-kLLXSd ylmAj" onClick={() => handlePayment()}>VIEW PRICE DETAILS</button>
                    </div>
                    <div className="sc-BrFsL iAFVK">
                        <button className="sc-ikZpkk bwHzRF" onClick={handlePayment}>PayNow</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payment;
