
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './Address.css'


const Address = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { totalPrice } = location.state || { totalPrice: 0 };
  // State for form inputs
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    pin: '',
    city: '',
    state: 'AP', // Default state
    flat: '',
    area: '',
  });

  // Load data from local storage when the component mounts
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('addressData'));
    if (savedData) {
      setFormData(savedData);
      console.log('Loaded data from local storage:', savedData); // Debugging line
    }
  }, []);

  // Update local storage whenever form data changes, but only if there's data
  useEffect(() => {
    if (formData.name || formData.number || formData.pin || formData.city || formData.flat || formData.area) {
      localStorage.setItem('addressData', JSON.stringify(formData));
      console.log('Updated local storage:', formData); // Debugging line
    }
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveAddressAndContinue = () => {
    // Optional: Add form validation here before navigating
    if (!formData.name || !formData.number || !formData.pin || !formData.city || !formData.flat || !formData.area) {
      alert("Please fill in all fields.");
      return;
    }
    navigate('/payment', { state: { totalPrice } });
  };


  return (
    <div>
      <div className='check _1FWdmb'>
        <div className='lefty'>
          <svg
            onClick={() => navigate(-1)}  // This navigates one step back in history
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            mt="2"
            iconsize="24"
            className="sc-gswNZR ffVWIj"
            style={{ cursor: 'pointer' }}
          >
            <path
              d="M13.7461 2.31408C13.5687 2.113 13.3277 2 13.0765 2C12.8252 2 12.5843 2.113 12.4068 2.31408L6.27783 9.24294C5.90739 9.66174 5.90739 10.3382 6.27783 10.757L12.4068 17.6859C12.7773 18.1047 13.3757 18.1047 13.7461 17.6859C14.1166 17.2671 14.0511 16.5166 13.7461 16.1718L8.29154 9.99462L13.7461 3.82817C13.9684 3.57691 14.1071 2.72213 13.7461 2.31408Z"
              fill="#666666"
            />
          </svg>
          <h6 className='header-title' style={{ fontFamily: 'Mier Book', margin: '0', width: '200px', fontSize: '13px' }}>
            ADD DELIVERY ADDRESS
          </h6>
        </div>
      </div>
      <div className="sc-bBXxYQ jMfCEJ"></div>
      <div className="card py-1 max-height" style={{ border: 'none' }}>
        <div data-testid="stepper-container" className="sc-geuGuN gqSLnX">
          <div className="sc-kGhOqx chtKwW">
            <ul className="sc-bAKPPm eOmvaT">
              <div className="sc-jZiqTT hGoFZP">
                <div className="sc-bxSTMQ geeMAN">
                  <div data-testid="left-line" className="sc-PJClH lagJzQ"></div>
                  <div className="sc-iJkHyd IhlWp">
                    <svg width="20" height="20" viewBox="0.5 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <title>check-icon</title>
                      <rect x="1.25" y="0.75" width="18.5" height="18.5" rx="9.25" fill="#5585F8"></rect>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.6716 7.37285C17.0971 6.96439 17.1108 6.28832 16.7023 5.86289C16.2939 5.43755 15.618 5.4238 15.1926 5.83218L10.9997 9.85723L10.9997 9.85727L9.02229 11.7557L6.82333 9.55674C6.40622 9.13963 5.72995 9.13963 5.31284 9.55674C4.8957 9.97388 4.89573 10.6502 5.31289 11.0673L8.26525 14.0192C8.66883 14.4227 9.32103 14.4293 9.73274 14.0341L10.9998 12.8178V12.8178L16.6716 7.37285Z"
                        fill="white"
                      ></path>
                      <rect x="1.25" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#5585F8" strokeWidth="1.5"></rect>
                    </svg>
                  </div>
                  <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                </div>
                <div className="sc-jWquRx ezBHwi">Cart</div>
              </div>
              <div className="sc-jZiqTT hGoFZP">
                <div className="sc-bxSTMQ geeMAN">
                  <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                  <div className="sc-dGHKFW cRaGaC">2</div>
                  <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                </div>
                <div className="sc-jWquRx iefUco">Address</div>
              </div>
              <div className="sc-jZiqTT hGoFZP">
                <div className="sc-bxSTMQ geeMAN">
                  <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                  <div className="sc-dGHKFW iefbLi">3</div>
                  <div data-testid="right-line" className="sc-jfdOKL bSausD"></div>
                </div>
                <div className="sc-jWquRx ezBHwi">Payment</div>
              </div>
              <div className="sc-jZiqTT hGoFZP">
                <div className="sc-bxSTMQ geeMAN">
                  <div data-testid="left-line" className="sc-PJClH kHHhBS"></div>
                  <div className="sc-dGHKFW iefbLi">4</div>
                  <div data-testid="right-line" className="sc-jfdOKL jSyZxf"></div>
                </div>
                <div className="sc-jWquRx ezBHwi">Summary</div>
              </div>
            </ul>
          </div>
        </div>
        <form method="post" id="addressForm">
          <div className="sc-fjqEFS bbBFRm">
            <div className="sc-bfKFlL beUHuI">
              <div className="sc-bcSJjp izYblt">
                <div className="sc-eBxihg lcYQRo">
                  <span className="sc-eEpejC iZWODx">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" fill="white" />
                  <path d="M15.2565 17.8276C15.2565 19.0296 12.9683 19.9999 10.1588 19.9999C7.34922 19.9999 5.06104 19.0296 5.06104 17.8276C5.07552 17.6683 5.14793 17.4945 5.22034 17.3642C5.72722 16.3794 7.75472 15.6553 10.1877 15.6553C12.6207 15.6553 14.6483 16.3794 15.1406 17.3642C15.2131 17.4945 15.2565 17.6683 15.2565 17.8276Z" fill="#3A66CF" />
                  <path d="M17.0527 6.4301C16.5313 -0.0434386 10.1157 7.62167e-06 10.1157 7.62167e-06C10.1157 7.62167e-06 3.6711 -0.0434386 3.14974 6.4301C2.70079 12.1651 8.49367 16.9152 9.82603 17.929C9.89844 17.9869 9.99982 18.0159 10.1012 18.0159C10.2026 18.0159 10.2895 17.9869 10.3764 17.929C11.7087 16.9152 17.5161 12.1651 17.0527 6.4301ZM10.1157 9.71756C9.57984 9.71756 9.05848 9.55826 8.62401 9.26861C8.17506 8.97897 7.82749 8.55899 7.62474 8.06659C7.42199 7.5742 7.36406 7.03835 7.46544 6.517C7.56681 5.99564 7.82749 5.51772 8.20403 5.14119C8.58056 4.76465 9.05848 4.50397 9.57984 4.4026C10.1012 4.30122 10.637 4.34467 11.1294 4.54742C11.6218 4.75017 12.0418 5.09774 12.3459 5.53221C12.6356 5.98116 12.7949 6.50251 12.7949 7.02387C12.7949 7.7335 12.5197 8.41416 12.0128 8.92104C11.506 9.42792 10.8253 9.71756 10.1157 9.71756Z" fill="#90B1FB" />
                </svg>

                  </span>
                  <span className="sc-hFvvYD gANwSd">Address</span>
                </div>
                <div className="form-floating mb-3 mt-3">
                  <input className="form-control" type="text" id="name" name="name" placeholder="Full name" required  value={formData.name}
                                        onChange={handleChange} />
                  <label htmlFor="name">Full Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input className="form-control" type="text" id="number" value={formData.number}
                                        onChange={handleChange} name="number" placeholder="Mobile number" required />
                  <label htmlFor="number">Mobile number</label>
                </div>
                <div className="form-floating mb-3">
                  <input className="form-control" type="text"  value={formData.pin}
                                        onChange={handleChange} id="pin" name="pin" placeholder="PIN code" required />
                  <label htmlFor="pin">Pincode</label>
                </div>
                <div className="row mb-3">
                  <div className="col-6 form-floating">
                    <input className="form-control" type="text"value={formData.city}
                                            onChange={handleChange} id="city" name="city" placeholder="Town/City" required />
                    <label htmlFor="city">City</label>
                  </div>
                  <div className="col-6 form-floating">
                    <select className="form-select" id="state" name="state"value={formData.state}
                                            onChange={handleChange} required>
                      <option value="AP">Andhra Pradesh</option>
                      <option value="AR">Arunachal Pradesh</option>
                      <option value="AS">Assam</option>
                      <option value="BR">Bihar</option>
                      <option value="CT">Chhattisgarh</option>
                      <option value="GA">Goa</option>
                      <option value="GJ">Gujarat</option>
                      <option value="HR">Haryana</option>
                      <option value="HP">Himachal Pradesh</option>
                      <option value="JK">Jammu & Kashmir</option>
                      <option value="JH">Jharkhand</option>
                      <option value="KA">Karnataka</option>
                      <option value="KL">Kerala</option>
                      <option value="MP">Madhya Pradesh</option>
                      <option value="MH">Maharashtra</option>
                      <option value="MN">Manipur</option>
                      <option value="ML">Meghalaya</option>
                      <option value="MZ">Mizoram</option>
                      <option value="NL">Nagaland</option>
                      <option value="OR">Odisha</option>
                      <option value="PB">Punjab</option>
                      <option value="RJ">Rajasthan</option>
                      <option value="SK">Sikkim</option>
                      <option value="TN">Tamil Nadu</option>
                      <option value="TS">Telangana</option>
                      <option value="TR">Tripura</option>
                      <option value="UK">Uttarakhand</option>
                      <option value="UP">Uttar Pradesh</option>
                      <option value="WB">West Bengal</option>
                      <option value="AN">Andaman & Nicobar</option>
                      <option value="CH">Chandigarh</option>
                      <option value="DN">Dadra and Nagar Haveli</option>
                      <option value="DD">Daman & Diu</option>
                      <option value="DL">Delhi</option>
                      <option value="LD">Lakshadweep</option>
                      <option value="PY">Puducherry</option>
                    </select>
                    <label htmlFor="state">State</label>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <input className="form-control" type="text" id="flat"value={formData.flat}
                                        onChange={handleChange} name="flat" placeholder="Flat, House.no, Building, Company" required />
                  <label htmlFor="flat">House No., Building Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input className="form-control" type="text"    value={formData.area}
                                        onChange={handleChange} id="area" name="area" placeholder="Area, Colony, Street, Sector, Village" required />
                  <label htmlFor="area">Road name, Area, Colony</label>
                </div>
                <div className="card-footer px-0">
                  <button
                    className="btn btn-dark save-address"
                    type="button" // Use "button" type to prevent form submission
                    onClick={handleSaveAddressAndContinue}

                  >
                    Save Address and Continue
                  </button>            </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  )
}

export default Address
