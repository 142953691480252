import React, { useState, useEffect } from 'react';
import Nav from './Nav/Nav';
import Images from './Images/Images';
import Loader from '../Loader/Loader'; // Assuming you already have a Loader component

const ProductDetails = () => {
  const [loading, setLoading] = useState(true); // State for loader

  useEffect(() => {
    // Simulate a delay to represent loading time (e.g., API call)
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds or after data is fetched
    }, 2000); // You can adjust the time or hook this to an actual data fetch

    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
  }, []);

  return (
    <>
      {loading ? (
        <Loader /> // Show loader while loading
      ) : (
        <>
          <Nav />
          <Images />
        </>
      )}
    </>
  );
};

export default ProductDetails;
