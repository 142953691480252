import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader'; // Assuming you have a Loader component
import '../../Home/Products/Products.css'; // Ensure the correct path
import '../../ProductDetails/Nav/Nav.css'; // Ensure the correct path
import CartSidebar from '../../CartSidebar/CartSidebar';
import API_URL from '../../../apiconfig';

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [products, setProducts] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [assuredImage, setAssuredImage] = useState('/assets/trust.svg'); // Default image
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const url = categoryName === 'categories'
          ? `${API_URL}/products/category/all`
          : `${API_URL}/products/category/${categoryName}`;
          
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedProducts = data.sort((a, b) => a.id - b.id);
        setProducts(sortedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAssuredImage = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/assured_image`); 
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const fetchedAssuredImage = data[0] || '/assets/trust.svg'; // Assuming the response is a single image URL
        setAssuredImage(fetchedAssuredImage);
      } catch (error) {
        console.error('Error fetching assured image:', error);
      }
    };

    fetchProducts();
    fetchAssuredImage();
  }, [categoryName]);

  useEffect(() => {
    const updateCartCount = () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      setCartCount(cart.length);
    };

    updateCartCount(); // Initial cart count update

    const handleStorageChange = (event) => {
      if (event.key === 'cart') {
        updateCartCount(); // Update cart count when `cart` changes
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader /> // Show loader while fetching products
      ) : (
        <>
          <div className='check' style={{ gap: '29px' }}>
            <div className='left'>
              <svg
                onClick={() => navigate('/')} // Navigate to root on click
                width="25" 
                height="25" 
                viewBox="0 0 20 20" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg" 
                style={{ cursor: 'pointer' }}
              >
                <path 
                  d="M13.7461 2.31408C13.5687 2.113 13.3277 2 13.0765 2C12.8252 2 12.5843 2.113 12.4068 2.31408L6.27783 9.24294C5.90739 9.66174 5.90739 10.3382 6.27783 10.757L12.4068 17.6859C12.7773 18.1047 13.3757 18.1047 13.7461 17.6859C14.1166 17.2671 14.0511 16.5166 13.7461 16.1718L8.29154 9.99462L13.7461 3.82817C13.9684 3.57691 14.1071 2.72213 13.7461 2.31408Z" 
                  fill="#666666"
                />
              </svg>
              <h6 style={{ margin: '0', width: '200px', fontSize: '14px' }}>
                {categoryName === 'all' ? 'All Products' : categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}
              </h6>
            </div>

            <div className='right'>
              <img src='/assets/heart.svg' alt='heart Icon' style={{ height: '24px', width: '24px' }} />
              <div style={{ position: 'relative' }}>
                <img 
                  src='/assets/cart.svg' 
                  alt='cart Icon' 
                  onClick={toggleSidebar}
                  style={{ height: '24px', width: '24px', cursor: 'pointer' }} 
                />
                {cartCount > 0 && (
                  <span className="navspan">
                    {cartCount}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="products" id="products">
            <div className="product-list">
              {products.map((product) => (
                <Link key={product.id} to={`/productDetails/${product.id}`} className="product-card">
                  <div className="product-img">
                    <img src={product.image} alt={product.name} />
                  </div>
                  <div className="product-details">
                    <h3 className="product-name">{product.name}</h3>
                    <div className="product-price">
                      <span className="sell-price">₹{product.sellingPrice}</span>
                      <span className="mrp-price line-through">₹{product.mrp}</span>
                      <span className="off-percentage m-0">
                        {Math.round(((product.mrp - product.sellingPrice) / product.mrp) * 100)}% off
                      </span>
                    </div>
                    <span className="NewProductCardstyled__OfferPill-sc-6y2tys-31 iMEkWH">
                      <img src="/assets/rupee.svg" alt="rupee icon" className="sc-bcXHqe eqGISc" />
                      <span fontSize="10px" color="greenBase" className="sc-gswNZR bjrKWS">
                        {product.offerText}
                      </span>
                    </span>
                    <p className="free-delivery">Free Delivery</p>
                    <div className="sc-kDvujY jTuxux NewProductCardstyled__RatingsRow-sc-6y2tys-8 heKerA" color="white">
                      <div className="NewProductCardstyled__RatingSection-sc-6y2tys-9 fyvrGC">
                        <span label={product.rating} className="sc-fbYMXx jUvjLH">
                          <span fontSize="13px" fontWeight="demi" color="#ffffff" className="sc-gswNZR jrrvJf">{product.rating}</span>
                          <img src="/assets/star.svg" alt="star icon" className="sc-bcXHqe cyUZOu" />
                        </span>
                        <span fontSize="11px" fontWeight="book" color="greyT2" className="sc-gswNZR gTFgDk NewProductCardstyled__RatingCount-sc-6y2tys-21 jZyLzI">
                          ({product.ratingNumber})
                        </span>
                      </div>
                      <img
                        src={assuredImage} // Use the fetched assured image
                        alt="trust icon"
                        className="sc-bcXHqe fUjwpe"
                        style={{ width: '55px', height: '20px', objectFit: 'cover' }}
                      />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <CartSidebar isOpen={isSidebarOpen} closeNav={closeSidebar} />
        </>
      )}
    </>
  );
};

export default CategoryPage;
