// Loader.js
import React from 'react';
import './Loader.css'; // Import the provided CSS

const Loader = () => {
  return (
    <div className="itivvW">
      <div className="ffUKkP">
        <div className="kIzYUf">
          <svg xmlns="http://www.w3.org/2000/svg" className="kxdQNm">
            <path d="M 7 30 L 7 18 A 7 7 0 0 1 21 18 L 21 30 M 21 18 A 7 7 0 0 1 35 18 L 35 30" className="dxZalB"></path>
            <path d="M 7 30 L 7 18 A 7 7 0 0 1 21 18 L 21 30" className="byJEYc"></path>
            <path d="M 21 30 L 21 18 A 7 7 0 0 1 35 18 L 35 30" className="byJEYc lpeJlm"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;
