import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Search from '../Search/Search';
import Category from './Category/Category';
import Banner from './Banners/Banner';
import Offer from './Offer/Offer';
import Loader from '../Loader/Loader'; // Import the Loader component
import BottomNavbar from './BottomNavBar/BottomNavBar';
import API_URL from '../../apiconfig';
import './Products/Products.css';

const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [assuredImage, setAssuredImage] = useState('/assets/trust.svg'); // Default to trust.svg
  const [loading, setLoading] = useState(true); // State to manage loading

  // Function to simulate a delay after data is fetched
  const setLoadingWithDelay = () => {
    setTimeout(() => {
      setLoading(false); // Set loading to false after 1-second delay
    }, 1000); // 1 second delay
  };

  // Fetch products from API on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${API_URL}/products`);
        const data = await response.json();
        setProducts(data);
        setLoadingWithDelay(); // Trigger the delay after data fetch
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoadingWithDelay(); // Even in case of error, apply the delay
      }
    };

    // Fetch homebanner assuredImage
    const fetchAssuredImage = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/assured_image`);
        const data = await response.json();
        const assuredImgFromAPI = data[0];
        if (assuredImgFromAPI) {
          setAssuredImage(assuredImgFromAPI);
        }
      } catch (error) {
        console.error('Error fetching assured image:', error);
      }
    };

    fetchProducts();
    fetchAssuredImage();
  }, []);

  // Handle search input change
  const handleSearch = (query) => {
    setSearchQuery(query); // Update search query
  };

  // Filter products based on search query, case-insensitive
  const filteredProducts = products
    .filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.id - b.id); // Sort products by id in ascending order

  // Show loader while loading
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <Search onSearch={handleSearch} /> {/* Pass search handler */}
      <Category />
      <Banner />
      <Offer />

      <div className="products" id="products">
        <h4 style={{ fontSize: '21px', color: 'Black' }} className="sc-gswNZR wDrko">
          Products For You
        </h4>
        <div className="product-list">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <Link key={product._id} to={`/productDetails/${product.id}`} className="product-card" data-id={product.id}>
                <div className="product-img">
                  <img src={product.image} alt={product.name} />
                </div>
                <div className="product-details">
                  <h3 className="product-name">{product.name}</h3>
                  <div className="product-price">
                    <span className="sell-price">₹{product.sellingPrice}</span>
                    <span className="mrp-price line-through">₹{product.mrp}</span>
                    <span className="off-percentage m-0">
                      {Math.round(((product.mrp - product.sellingPrice) / product.mrp) * 100)}% off
                    </span>
                  </div>
                  <span className="NewProductCardstyled__OfferPill-sc-6y2tys-31 iMEkWH">
                    <img src="/assets/rupee.svg" alt="rupee icon" className="sc-bcXHqe eqGISc" />
                    <span fontSize="10px" color="greenBase" className="sc-gswNZR bjrKWS">
                      {product.offerText}
                    </span>
                  </span>
                  <p className="free-delivery">Free Delivery</p>
                  <div className="sc-kDvujY jTuxux NewProductCardstyled__RatingsRow-sc-6y2tys-8 heKerA" color="white">
                    <div className="NewProductCardstyled__RatingSection-sc-6y2tys-9 fyvrGC">
                      <span label={product.rating} className="sc-fbYMXx jUvjLH">
                        <span fontSize="13px" fontWeight="demi" color="#ffffff" className="sc-gswNZR jrrvJf">
                          {product.rating}
                        </span>
                        <img src="/assets/star.svg" alt="star icon" className="sc-bcXHqe cyUZOu" />
                      </span>
                      <span fontSize="11px" fontWeight="book" color="greyT2" className="sc-gswNZR gTFgDk NewProductCardstyled__RatingCount-sc-6y2tys-21 jZyLzI">
                        ({product.ratingNumber})
                      </span>
                    </div>
                    {/* Use assuredImage fetched from homebanner or default to trust.svg */}
                    <img
                      src={assuredImage || '/assets/trust.svg'}
                      alt="trust icon"
                      className="sc-bcXHqe fUjwpe"
                      style={{ width: '55px', height: '20px', objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p className="noProduct">No products found.</p>
          )}
        </div>
      </div>
      <BottomNavbar />
    </>
  );
};

export default Home;
