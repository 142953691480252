import React, { useEffect, useState } from 'react';
import './Navbar.css';
import CartSidebar from '../CartSidebar/CartSidebar';
import API_URL from '../../apiconfig';

const defaultLogo = '/assets/logo.svg'; // Path to the default logo

const Navbar = () => {
    const [logo, setLogo] = useState(defaultLogo);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchLogo = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const response = await fetch(`${API_URL}/homebanner/logo_link`);
                const data = await response.json();
                const fetchedLogo = data.length > 0 ? data[0] : '';
                setLogo(fetchedLogo || defaultLogo);
            } catch (error) {
                console.error('Error fetching logo:', error);
                setLogo(defaultLogo);
            } finally {
                setLoading(false); // Set loading to false after fetch completes
            }
        };

        fetchLogo();
        updateCartCount();

        const handleStorageChange = (event) => {
            if (event.key === 'cart') {
                updateCartCount();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const updateCartCount = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartCount(cart.length);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    // Render nothing if loading
    if (loading) {
        return null; // or return <></>; to render an empty fragment
    }

    return (
        <>
            <div className='check' id='touchit'>
                <div className='left'>
                    <img src='/assets/hamburger.svg' alt='Hamburger Icon' style={{ height: '24px', width: '24px' }} />
                    <img src={logo} alt='logo' style={{ height: "25px", width: "90px" }} />
                </div>

                <div className='right'>
                    <img src='/assets/heart.svg' alt='heart Icon' style={{ height: '24px', width: '24px' }} />
                    <div style={{ position: 'relative' }}>
                        <img
                            src='/assets/cart.svg'
                            alt='cart Icon'
                            onClick={toggleSidebar}
                            style={{ cursor: 'pointer' }}
                        />
                        {cartCount > 0 && (
                            <span className="navspan">
                                {cartCount}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <CartSidebar isOpen={isSidebarOpen} closeNav={closeSidebar} />
        </>
    );
};

export default Navbar;
