import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CartSidebar from '../../CartSidebar/CartSidebar';

const Buttons = ({ productId, selectedVariants, activeImage }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility
    const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('cart')) || []); // Track cart items
    const navigate = useNavigate(); // Initialize useNavigate to programmatically navigate

    const addToCart = () => {
        // Get the existing cart from localStorage
        let newCart = JSON.parse(localStorage.getItem('cart')) || [];

        // Add the product ID, selected variants, active image, and quantity 1 to the cart
        newCart.push({ productId, quantity: 1, variants: selectedVariants, image: activeImage });

        // Store the updated cart back in localStorage
        localStorage.setItem('cart', JSON.stringify(newCart));

        // Update the cart state to reflect changes
        setCart(newCart);

        console.log(`Product ${productId} added to cart with quantity 1 and variants:`, selectedVariants);
        console.log(newCart);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Function to close sidebar
    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    const buyNow = () => {
        // Add the product to the cart in the same way as the addToCart function
        let newCart = JSON.parse(localStorage.getItem('cart')) || [];

        // Add the product ID, selected variants, active image, and quantity 1 to the cart
        newCart.push({ productId, quantity: 1, variants: selectedVariants, image: activeImage });

        // Store the updated cart back in localStorage
        localStorage.setItem('cart', JSON.stringify(newCart));

        // Update the cart state
        setCart(newCart);

        console.log(`Product ${productId} added to cart with quantity 1 for Buy Now with variants:`, selectedVariants);

        // Navigate to the cart route
        navigate('/cart');
    };

    return (
        <>
            <div className="button-container flex">
                <button
                    className="buynow-button buynow-button-white product-page-buy add_cart"
                    onClick={() => {
                        addToCart();
                        toggleSidebar();
                    }}
                >
                    <svg
                        width="21"
                        height="21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        ml="4"
                        mr="4"
                        iconsize="20"
                        className="sc-gswNZR egWgdn"
                    >
                        <g clipPath="url(#cart_svg__a)">
                            <path fill="#fff" d="M.394.479h20v20h-20z"></path>
                            <g clipPath="url(#cart_svg__b)">
                                <g clipPath="url(#cart_svg__c)">
                                    <path
                                        d="M4.396 4.293h15.139c.507 0 .907.491.849 1.046l-.762 7.335c-.068.619-.536 1.1-1.102 1.12l-12.075.492-2.05-9.993Z"
                                        fill="#C53EAD"
                                    ></path>
                                    <path
                                        d="M10.193 20.478c.674 0 1.22-.598 1.22-1.335s-.546-1.334-1.22-1.334c-.674 0-1.22.597-1.22 1.334 0 .737.546 1.335 1.22 1.335ZM15.181 20.478c.674 0 1.22-.598 1.22-1.335s-.546-1.334-1.22-1.334c-.674 0-1.22.597-1.22 1.334 0 .737.546 1.335 1.22 1.335Z"
                                        fill="#9F2089"
                                    ></path>
                                    <path
                                        d="M1.126 3.28 4.152 4.7 6.68 16.784c.127.608.615 1.035 1.18 1.035h9.615"
                                        stroke="#9F2089"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </g>
                            </g>
                        </g>
                        <defs>
                            <clipPath id="cart_svg__a">
                                <path fill="#fff" transform="translate(.394 .479)" d="M0 0h20v20H0z"></path>
                            </clipPath>
                            <clipPath id="cart_svg__b">
                                <path fill="#fff" transform="translate(.394 .479)" d="M0 0h20v20H0z"></path>
                            </clipPath>
                            <clipPath id="cart_svg__c">
                                <path fill="#fff" transform="translate(.394 2.479)" d="M0 0h20v18H0z"></path>
                            </clipPath>
                        </defs>
                    </svg>
                    Add to Cart
                </button>
                <button
                    className="buynow-button product-page-buy buy_now"
                    onClick={buyNow}
                >
                    <svg
                        width="21"
                        height="21"
                        fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                        ml="4"
                        mr="4"
                        stroke="transparent"
                        btntype="solid"
                        className="sc-gswNZR dXbsni PdpFooter__SolidButtonBigStyled-sc-rnhxff-1 kEhBpR"
                        iconsize="20"
                    >
                        <path
                            d="M1.894 4.546v11.796a.5.5 0 0 0 .837.369l6.74-6.18a.5.5 0 0 0-.017-.752l-6.74-5.617a.5.5 0 0 0-.82.384ZM11.894 4.546v11.796a.5.5 0 0 0 .837.369l6.74-6.18a.5.5 0 0 0-.017-.752l-6.74-5.617a.5.5 0 0 0-.82.384Z"
                            fill="#fff"
                        ></path>
                    </svg>
                    Buy Now
                </button>
            </div>
            <CartSidebar isOpen={isSidebarOpen} closeNav={closeSidebar} cart={cart} /> {/* Pass updated cart */}
        </>
    );
};

export default Buttons;
