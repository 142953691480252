import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Category.css';
import API_URL from '../../../apiconfig';

const Category = () => {
  const [categories, setCategories] = useState([]);

  // Fetch categories data directly from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/category`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Extract categories from the nested array structure
        const categoryList = Array.isArray(data) && data.length > 0 ? data[0] : [];
        setCategories(categoryList);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="categories">
      <div className="cat-list">
        {/* Render categories dynamically from the API response */}
        {categories.map((cat) => (
          <Link key={cat._id} className="category" to={`/category/${cat.name.toLowerCase()}`}>
            <img src={cat.link} alt={cat.name} />
            <p>{cat.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
